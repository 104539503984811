import { render, staticRenderFns } from "./modalnputDetail.vue?vue&type=template&id=fa8deee0&scoped=true"
import script from "./modalnputDetail.vue?vue&type=script&lang=js"
export * from "./modalnputDetail.vue?vue&type=script&lang=js"
import style1 from "./modalnputDetail.vue?vue&type=style&index=1&id=fa8deee0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa8deee0",
  null
  
)

export default component.exports